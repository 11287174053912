import React from 'react';
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { toast } from "react-toastify";
import Typed from 'react-typed';
import SimpleMap from 'components/Map/SimpleMap';
import { useAppContext } from "../../lib/contextLib";
import { useEffect, useRef, useState } from "react";
import { useTheme } from '@mui/material/styles';
import { Checkbox, IconButton, Link, Stack, Box, Typography, Dialog, FormControl, OutlinedInput, CircularProgress, Button } from "@mui/material";
import config from '../../config';
import { API, Storage } from "aws-amplify";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import FakeTweet from "fake-tweet";
import { Simple } from 'blocks/tables';
import { s3Upload } from "../../lib/awsLib";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import DownloadIcon from '@mui/icons-material/DownloadOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import * as htmlToImage from 'html-to-image';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Media from 'components/FakeFaceBook/Media';




const messageType = {
  answer: "answer",
  question: "question",
  inject: "inject"
};

const productTypes = {
  'ama': 'Ask Anything',
  'tweet': 'Inject (Tweet)',
  'fb': 'Inject (Facebook)',
  'ascope': 'ASCOPE/PMESII',
  'tem': 'Terrain Effects Matrix',
  'tdt': 'Threat Description Table',
  'hvt': 'High-Value Targets',
  //'threat_cap': 'Threat Capability Statement',
  //'threat_coa': 'Threat COA Statement',
  //'sitemp': 'SITEMP',
}

const introMessage = new Map([
  ["ama", `Welcome to Pytho, your AI Wargaming Expert. Supercharge your wargaming operations workflows working with Pytho. 
  <br /><br />
  Not sure where to start? You can try:
  `],
  ["blue", `Welcome to our Scenario Design channel! This is built specifically to design scenarios for your live, virtual, and constructive training events.
  <br /><br />
  Not sure where to start? You can try:
  `],
  ["inject", `Welcome to our Inject Generation page! Injects drive exercise play and enhance training by making it more realistic and dynamic.
  <br /><br />
  <em>Right now, only Tweets are supported. More social media and OSINT formats are under development.</em>
  <br /><br />
  For example:
  `],
  ["ipb", `Welcome to our Create Products channel! This is built specifically to help warfighters create complex products like reports, tables, maps, and more in a fraction of the time.
  <br /><br />
  Not sure where to start? You can try:
  `],
]);

const tooltipPrompts = new Map([
  ["ama", [    
    'Summarize MCDP-2 Intelligence in 250 words or less.',
    'What is the format of an Army Operation order?',
    'We are conducting Intelligence Preparation of the Battlefield for a scenario in which China invades Taiwan. What are the most likely and the most dangerous courses of action that the Chinese military would take in an invasion?',
    'Give me a detailed Order of Battle of the PLAN\'s East Sea Fleet.',
    'Create me an HVT List for Russia\'s 1st Guards Tank Army.',
    'Generate 15 Tweets that show indications and warnings of a hostile attack on a coalition forces FOB.',
    'Generate 5 Facebook posts that show the aftermath of a missile attack in Ukraine.',
    'Create a Terrain Effects Matrix for Beirut, Lebanon.'
  ]],
  ["blue", [
    'Design a scenario for a tabletop exercise for my special operations team. The scenario should be based on an amphibious landing on an island to rescue hostages taken by a guerrilla group.',
    'Tell me a story about how conflict could escalate between Russia and the US in the Arctic.',
    'Write an after action review of lessons learned from a US pilot who was harassed by Chinese aircraft in the South China Sea.',
  ]],
  ["inject", [
    'Generate 20 tweets that show indications and warnings of an insurgent attack on a coalition forces base.',
    'Generate 5 tweets about an unidentified drone flying over a village suspected of harboring a known terrorist leader.',
  ]],
  // Change the tooltipProductTypes, too.
  ["ipb", [
    'Create an ASCOPE/PMESII table for Khorathidin',
    'Create a terrain effects matrix for the enemy forces involved in threat course of action 1',
    'Create a threat description table for the 71 ACG',
    'Create a high value target list for the 71 army combat group',
    // "Create a sitemp for 71st ACG"
  ]],
]);

const tooltipProductTypes = [
  ['ama', "ama"],
  ['ama', "ama"],
  ['ama', "ama"],
  ['ama', "ama"],
  ['ipb', 'hvt'],
  ['inject', 'tweet'],
  ['inject', 'fb'],
  ['ipb', 'tem']
]

const files = [
  {
    name: "01_Ann_B_Intel_OPORD",
    url: "/ipb/IPSR03_B_01_Ann_B_Intel_OPORD_12030-03.docx",
  },
  {
    name: "02_App_01_Intel_Est_OPORD",
    url: "/ipb/IPSR03_B_02_App_01_Intel_Est_OPORD_12030-03.docx",
  }, 
  // "frontend/public/ipb/IPSR03_B_03_Tab_A01_Terrain_OPORD_12030-03.docx", 
  // "frontend/public/ipb/IPSR03_B_04_Exh_01A01_Chao_Phraya_Bridges_OPORD_12030-03.pdf", 
  // "frontend/public/ipb/IPSR03_B_05_Exh_02A01_Nueng_Sai_Kao_Bridges_OPORD_12030-03.pdf", 
  // "frontend/public/ipb/IPSR03_B_06_Exh_03A01_Chai_Badan_Mountains_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_07_Exh_04A01_Pa_Sak_Bridges_OPORD_12030-03.pdf", 
  // "frontend/public/ipb/IPSR03_B_08_Exh_05A01_Watershed_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_09_Exh_06A01_AAs_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_10_Exh_07A01_AA4_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_11_Exh_08A01_AA5_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_12_Exh_09A01_AA5a_OPORD_12030-03.pdf",
  // "frontend/public/ipb/IPSR03_B_13_Exh_10A01_Key_Terrain_OPORD_12030-03.pdf", 
  // "frontend/public/ipb/IPSR03_B_14_Tab_B01_Weather_OPORD_12030-03.pptx",
  // "frontend/public/ipb/IPSR03_B_16_Exh_03D01_Threat_COAs_OPORD_12030-03.pptx",
]

export default function Chat() {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const { 
    user, question, setQuestion, setOpenSidebar, team, setTeam, session, setSession, 
    sessionId, setSessionId, messages, setMessages, docs, setDocs } = useAppContext();
  const theme = useTheme();
  const { setMode } = theme;

  const [onRequest, setOnRequest] = useState(false);
  const [onTypingComplete, setOnTypingComplete] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const [drawMap, setDrawMap] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [typeEffect, setTypeEffect] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [productType, setProductType] = useState('ama');


  const handlePopClick = (event) => {
    setOpenPop(true);
  };

  const downloadClick = (itemIndex) => (event) => {
    var question = messages[itemIndex-1].content
    var item = messages[itemIndex]
        
    var mapHtml = ''    
    
    item.maps.map((map, index) => {
      const fileName = `${map.id}.png`
      mapHtml += `<br/><img src="${fileName}"></img>`
      const blob = htmlToImage.toBlob(document.getElementById(map.id))
        .then(function (blob) {
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement("a")
          a.href = url
          a.download = fileName
          a.click()
          window.URL.revokeObjectURL(url)          
        });
        
    })

    var tableHtml = ''
    item.tables.map((table, index) => {
      tableHtml += `<b>${table.title}</b><br/><br/><table>`
      
      tableHtml += '<tr>'
      table.columns.map((column) => (
        tableHtml += '<th>' + column + '</th>'
      ))
      tableHtml += '</tr>'
      table.rows.map((row) => {
        tableHtml += '<tr>'
        if (row.name) {
          tableHtml += '<th>' + row.name + '</th>'  
        }
        row.cells.map((cell) => (
          tableHtml += '<td>' + cell.text + '</td>'
        ))
        tableHtml += '</tr>'
      })

      tableHtml += '</table><br/>'
    })

    var blobHtml = `<!DOCTYPE html>
<html>
<head>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>
</head>
<body>
    `
    blobHtml += `<b>${question}</b><br/><br/>` + tableHtml + item.content + mapHtml
    const host =  window.location.protocol + '//' + window.location.host
    blobHtml = blobHtml.replaceAll('href="', 'href="' + host)
    blobHtml += `</body></html>`
    var blob = new Blob([blobHtml], {type: "text/html"});
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = 'pytho-download.html';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handlePopClose = () => {
    setOpenPop(false)
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  function formatFilename(str) {
    return str.replace(/^\w+-/, "");
  }

  async function handleFileChange(event) {
    if (event.target.files.length > 0) {
      setTeam('doc')
    }
    setMessages([])
    setSession([])
    setSessionId(crypto.randomUUID())
    setDocs([])
    setUploading(true)  
    const contextDocs = []
      
    for (let i=0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      if (file.size > config.MAX_ATTACHMENT_SIZE) {
        alert(
          `Please pick a file smaller than ${
            config.MAX_ATTACHMENT_SIZE / 1000000
          } MB.`
        );
        
        return 
      }

      if (file) {
        const attachment = await s3Upload(file);
        const attachmentURL = await Storage.vault.get(attachment);
        contextDocs.push({attachment, attachmentURL})
      }
      
    }
    setDocs(contextDocs)
    setUploading(false)
        
    //getAnswerWithPrompt("Summarize the context in 100 words or less.", None, contextDocs, [], '')
    inputRef.current.focus()
  }

  const submitFeedback = async () => {
    if (feedback.trim().length < 3) return;

    try {
      if (!user) {
        toast.error('Please sign in');
        return
      }

      handlePopClose()
      const response = await API.post("public", `/chat`, {
        body: {
          userId: user.userId,
          email: user.email,
          message: feedback,
        }
      });

      setFeedback("")

    } catch (e) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  async function getAnswerWithPrompt(
    prompt, currentTeam=team, prdType=productType, pastSession=session, pastSessionId=sessionId, contextDocs=docs, pastMessages=messages
  ) {

    if (onRequest) return;
    prompt = prompt.trim()
    if (prompt.length < 3) return;
    const newMessages = [...pastMessages, {
      type: messageType.question,
      content: prompt,
    }];
    setMessages(newMessages);
    setOnRequest(true);
    try {
      if (!user) {
        toast.error('Please sign in');
        return
      }

      var docsKey = ''
      if (currentTeam === 'doc') {
        for (let i=0; i < contextDocs.length; i++) {
          if (i > 0) {
            docsKey += '|'
          }
          docsKey += contextDocs[i].attachment
        }
      }

      const websocket = new WebSocket(config.webSocketApiGateway.URL);
      
      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            action: 'chat',
            userId: user.userId,
            email: user.email,
            session: pastSession,
            sessionId: pastSessionId,
            productType: prdType,
            drawMap: drawMap,
            prompt: currentTeam + ':' + (currentTeam === 'doc' ? docsKey + ':' : '') + prompt,
          })
        );
      };

      websocket.onmessage = ({ data }) => {
        data = JSON.parse(data)
        setSession(data.session)
        var htmlResponse = data.answer

        function parse(re, text) {
          const matches = re.exec(text)
          if (matches) {
            var degrees = parseInt(matches[1])
            var minutes = parseInt(matches[2])
            var seconds = parseInt(matches[3])
            var direction = matches[4]

            var dd = degrees + minutes / 60 + seconds / (60 * 60);

            if (direction == "S" || direction == "W") {
              dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
          }

          return null
        }
        
        function parseMap(text) {
          const mapPattern = /[\s|\n|\r]*?<map>((.|\n)*?)<\/map>[\s|\n|\r]*?/gm
          const matches = [...text.matchAll(mapPattern)]
          const maps = []
          
          for (let i = 0; i < matches.length; i++) {
            try {
              const mapJSON = JSON.parse(matches[i][1].trim())
              if (mapJSON && ((mapJSON.units && mapJSON.units.length > 0) || (mapJSON.infrastructure && mapJSON.infrastructure.length > 0))) {
                mapJSON['id'] = Math.floor(Math.random() * 1000).toString()
                maps.push(mapJSON)
              }
            } catch (e) {
              // handle this
            }
          }
          return maps  
        }

        function parseTweets(tweets) {
          const tweetInjects = []

          for (let i = 0; i < tweets.length; i++) {
            const nameMatches = [...tweets[i][1].matchAll(/<name>((.|\n)*?)<\/name>/gm)]
            const avatarMatches = [...tweets[i][1].matchAll(/<avatar>((.|\n)*?)<\/avatar>/gm)]
            const messageMatches = [...tweets[i][1].matchAll(/<message>((.|\n)*?)<\/message>/gm)] 
            if (nameMatches.length > 0 && avatarMatches.length > 0 && messageMatches.length > 0) {
              const name = nameMatches[0][1]
              const nickname = name.replace(/\s/g,'_').toLowerCase() +  Math.floor(Math.random() * 100)
              tweetInjects.push({
                user: {
                  nickname: nickname,
                  name: name,
                  avatar: avatarMatches[0][1],
                  verified: Math.random() > .6 ? true : false,
                  locked: false
                },
                hour: Math.ceil(Math.random() * 10),
                display: "default",
                text: messageMatches[0][1],
                //image: "",
                //date: "",
                //app: "",
                retweets: Math.floor(Math.random() * 100),
                quotedTweets: Math.floor(Math.random() * 10),
                likes: Math.floor(Math.random() * 10000)
              })
            }  
          }

          return tweetInjects
        }

        const tweets = [...htmlResponse.matchAll(/<tweet>((.|\n)*?)<\/tweet>/gm)]
        const fbPosts = [...htmlResponse.matchAll(/<post>((.|\n)*?)<\/post>/gm)]

        const latitude = parse(/\s*([0-9]+)°\s*([0-9]+)['|’]\s*([0-9]+\.?[0-9]*)["|”]\s*([N|S])/g, htmlResponse)
        const longitude = parse(/\s*([0-9]+)°\s*([0-9]+)['|’]\s*([0-9]+\.?[0-9]*)["|”]\s*([E|W])/g, htmlResponse) 
        const questionPattern = /[\s|\n|\r]*?<question>((.|\n)*?)<\/question>[\s|\n|\r]*?/gm
        const confidencePattern = /[\s|\n|\r]*?<confidence>((.|\n)*?)<\/confidence>[\s|\n|\r]*?/gm
        const cotPattern = /[\s|\n|\r]*?<chain_of_thoughts>((.|\n)*?)<\/chain_of_thoughts>[\s|\n|\r]*?/gm
        const followupMatches = [...htmlResponse.matchAll(questionPattern)]
        const confidenceMatches = [...htmlResponse.matchAll(confidencePattern)]
        const cotMatches = [...htmlResponse.matchAll(cotPattern)]

        var confidence = confidenceMatches.length > 0 ? confidenceMatches[0][1].trim() : null
        if (confidence) {
          confidence = ('' + Math.max(0, parseInt(confidence) - Math.floor(Math.random() * 5))) + '%' 
        }

        const cot = cotMatches.length > 0 ? cotMatches[0][1].trim().replace(/\n/g, "<br/>") : null

        htmlResponse = htmlResponse
          .replace(confidencePattern, '')
          .replace(cotPattern, '')

        const maps = parseMap(htmlResponse)
        const followupQuestions = []
        for (let i = 0; i < followupMatches.length; i++) {
          const q = followupMatches[i][1].trim()
          followupQuestions.push(q.replace(/^\d+. /, ""))  
        }

        const tablePattern = /<table>((.|\n)*?)<\/table>/gm
        const tableMatches = [...htmlResponse.matchAll(tablePattern)]
        const tables = []
        for (let i = 0; i < tableMatches.length; i++) {
          try {
            const tableJSON = JSON.parse(tableMatches[i][1].trim())
            const columns = []
            const rows = []
            var firstRow = true

            for (const key in tableJSON) {
              // row
              if (key != 'Title') {
                const row = tableJSON[key]
                const rowName = isNaN(key) ? key.replaceAll('_', ' ') : '' // row have a numeric label
                const cells = []

                if (firstRow) {
                  for (const column in row) {
                    columns.push(column)
                  }
                }

                for (const columnId in columns) {
                  const column = columns[columnId]
                  if (column in row) {
                    const columnName = column.replaceAll('_', ' ')

                    cells.push({
                      title: columns.length > 1 ? (rowName ? rowName + ' - ' : '') + columnName : rowName, 
                      text: row[column],
                    })  
                  } else {
                    cells.push('')
                  }
                }
                rows.push({
                  name: rowName,
                  cells: cells,
                })
                firstRow = false
              }
            }

            // rows have label
            if (rows.length > 0 && rows[0].name) {
              columns.unshift('') // add an empty column to accomodate row labels
            }
            columns.forEach((column, index) => {
              columns[index] = column.replaceAll('_', ' ')
            })

            tables.push({ 
              title: tableJSON.Title,
              columns: columns,
              rows: rows,
            })
          } catch (e) {
            htmlResponse = 'Something went wrong. Please try again later.';
          }
        }

        const ampMatches = [...htmlResponse.matchAll(/\s[^\s"]*&/gm)]
        for (let i = 0; i < ampMatches.length; i++) {
          htmlResponse = htmlResponse.replace(ampMatches[i][0], ampMatches[i][0] + 'amp;')
        }

        for (let i = 0; i < contextDocs.length; i++) {
          htmlResponse = htmlResponse.replaceAll(`s3://doc.${i}`, contextDocs[i].attachmentURL)
        }

        const images = [...htmlResponse.matchAll(/<image>((.|\n)*?)<\/image>/gm)]
        
        //setTypeEffect(true)
        htmlResponse = htmlResponse.replace(/<location>((.|\n)+?)<\/location>\n*/g, '')
          .replace(/<map>((.|\n)+?)<\/map>\n*/g, '')
          .replace(/<tweet>((.|\n)+?)<\/tweet>\n*/g, '')
          .replace(/<post>((.|\n)+?)<\/post>\n*/g, '')
          .replace(/<image>((.|\n)+?)<\/image>\n*/g, '')
          .replace(/<scenario>\n*/g, '')
          .replace(/<\/scenario>\n*/g, '')
          .replace(/<answer>\n*/g, '')
          .replace(/<\/answer>\n*/g, '')
          .replace(questionPattern, '')
          .replace(tablePattern, '')
          .trim()
          .replace(/\n/g, "<br/>")
          .replace('context:', 'context')

        // contains table, find the sources
        // if (tables.length > 0) {
        //   const sourcesStart = htmlResponse.indexOf('<br/><b>Sources</b>')
        //   if (sourcesStart > -1) {
        //     htmlResponse = '<br/>' + htmlResponse.substring(sourcesStart)
        //   } else {
        //     htmlResponse = ''
        //   }
            
        // }

        const fbInjects = parseTweets(fbPosts)
        for (let i = 0; i < fbInjects.length && i < images.length; i++) {
          const post = fbInjects[i]
          fbInjects[i] = { ... post, media: images[i][1] }
        }

        setMessages([...newMessages, {
          maps: maps,
          type: messageType.answer,
          content: htmlResponse,
          tables: tables,
          latitude: latitude,
          longitude: longitude,
          confidence: confidence,
          tweetInjects: parseTweets(tweets),
          fbInjects: fbInjects,
          cot: cot,
          followupQuestions: followupQuestions,
        }]);
        

        websocket.close();
        setOnRequest(false)
        setUploading(false)
        setOnTypingComplete(false)
      };


    } catch (e) {
      toast.error('Something went wrong!');
      setOnRequest(false);
    }

  }

  const getAnswer = async () => {
    if (onRequest) return;
    if (question.trim().length < 3) return;
    setQuestion("")
    var modifiedQuestion = question
    // if (team === 'ipb') {
    //   modifiedQuestion = productTypes[productType] + ' for ' + modifiedQuestion  
    // }
  
    getAnswerWithPrompt(modifiedQuestion)

  };

  // const onSignOut = () => {
  //   localStorage.removeItem("tkn");
  //   navigate("/signin");
  // };

  useEffect(() => {
    setMode('dark');
    setOpenSidebar(true);
    var latestScroll = -1
    chatWrapperRef.current.addEventListener("DOMNodeInserted", e => {
      if (e.currentTarget.scrollHeight && e.currentTarget.scrollHeight != latestScroll) {
        latestScroll = e.currentTarget.scrollHeight;
        e.currentTarget.scroll({
          top: e.currentTarget.scrollHeight,
          behavior: "smooth"
        });
      }

    });

  }, []);

  return (
    <Box sx={{ 
      display: "flex",
      justifyContent: "left",
      paddingLeft: 20,
      // flexDirection: 'row',
      // display: 'flex',
      // justifyContent: 'space-between' 
    }}
    >
    <Box
      height={'90vh'}
      marginBottom={4}
      maxWidth= {"md"}
      width= {"100%"}    
      marginTop={{xs: 2, md: 4}}      
    >
      <Stack
        justifyContent="flex-end"
        sx={{ height: "100%" }}
      >
        <Box ref={chatWrapperRef} sx={{
          height: "100%",
          position: "fixed",
          zIndex: 1,
          width: "100%",
          overflowY: "auto",
          paddingTop: { xs: "30px", md: "60px" },
          paddingBottom: { xs: "200px", md: "150px" },
          "&::-webkit-scrollbar": {
            width: "0px"
          }}}
        >
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            width: "100%"
          }}>
            {team === 'doc' && !isUploading && <Box sx={{
              paddingLeft: {xs: 4},
              paddingBottom: {xs: 4},
              bgcolor: "#2f2f2f",
              borderRadius: 3,
              width: '60%' 
            }}>
              <Box marginBottom={4}>
                {docs.map((doc) => 
                  <><br />
                  <Typography
                    variant='body2'
                    component={Link}
                    style={{ cursor: 'pointer' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={doc.attachmentURL}    
                  >
                    {formatFilename(doc.attachment)}
                  </Typography> 
                </>)}
              </Box>
             <Button
                variant="outlined"
                color="primary"
                component="label"
                marginTop={4}
                onClick={() => {
                  setTeam('ama')
                  setProductType('ama')
                  setMessages([])
                  setSession([])
                  setSessionId(crypto.randomUUID())
                  setDocs([])
                }}
                disabled={isUploading}
                
              >
                Remove 
              </Button>
            </Box>}
            {messages.length == 0 && team != 'doc' && introMessage.get('ama') && <Box sx={{
              padding: {xs: 2, md: 5},
              bgcolor: "#2f2f2f",
              maxWidth: "md",
              borderRadius: 3
            }}>
              <Typography
                dangerouslySetInnerHTML={{ __html: introMessage.get('ama') }}
              />
              <List
                sx={{
                  width: '100%',
                  listStyleType: 'disc',
                  padding: 2
                }}

              >
                {tooltipPrompts.get('ama').map((prompt, index) => (
                  <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                    <Typography
                      component={Link}
                      style={{ cursor: 'pointer' }}
                      color={'text.primary'}
                      onClick={() => {
                        if (tooltipProductTypes[index][1] != productType) {
                          setSession([])
                          setSessionId(crypto.randomUUID())
                        }
                        setTeam(tooltipProductTypes[index][0])
                        setProductType(tooltipProductTypes[index][1])
                        
                        getAnswerWithPrompt(prompt, tooltipProductTypes[index][0], tooltipProductTypes[index][1])
                      }}
                    >
                      {prompt}
                    </Typography>
                  </ListItem>))}
              </List>
              {/* <br /><br /><br />
              Note that I'm currently under development, so please submit <Typography
                component={Link}
                style={{ cursor: 'pointer' }}
                onClick={handlePopClick}
              >
                feedback
              </Typography> to help me improve. */}

            </Box>}
            {messages.map((item, index) => (
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
              }}>
              <Box sx={{
                padding: 3,
                bgcolor: item.type !== messageType.question && "#2f2f2f",
                borderRadius: 3,
                width: "60%",
              }}>
                
                {index === messages.length - 1  && typeEffect ? (
                  item.type === messageType.answer ? (
                    <Box>                
                      {item.tables && item.tables.length > 0 && <>
                        <Simple table={item.tables[0]}/>
                      </>}
                      <Typed
                        showCursor={false}
                        strings={[item.content]}
                        typeSpeed={1}
                        onComplete={() => {
                          setOnRequest(false);
                          setTimeout(() => {
                            inputRef.current.focus();
                          }, 200);
                          
                          setOnTypingComplete(true)
                          setTypeEffect(false)
                        }}
                      />  

                      {onTypingComplete && item.maps && item.maps.length > 0 && <>
                        <br /><br />
                        <SimpleMap
                          height={'480px'}
                          map={item.maps[0]}
                        />
                      </>}
                      {onTypingComplete && item.followupQuestions && item.followupQuestions.length > 0 && <>
                      <br/><b>Related Questions</b><br/>
                      <List
                        sx={{
                          width: '100%',
                          listStyleType: 'disc',
                          padding: 2
                        }}
                      >
                        {item.followupQuestions.map((prompt, index) => (
                          <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                            <Typography
                              component={Link}
                              style={{ cursor: 'pointer' }}
                              color={'text.primary'}
                              onClick={() => {
                                getAnswerWithPrompt(prompt)
                              }}
                            >
                              {prompt}
                            </Typography>
                          </ListItem>))}
                      </List>
                      </>}
                    </Box>
                  ) :  <>  
                    {item.tweetInjects && item.tweetInjects.map((inject, index) => (
                      <><FakeTweet config={inject} /> <br /></>
                    ))}
                    {item.fbInjects && item.fbInjects.map((inject, index) => (
                      <><Media post={inject}/> </>
                    ))}
                    <Typography dangerouslySetInnerHTML={{ __html: item.content }} />
                  </>
                ) : (
                  <>
                    {item.tables && item.tables.length > 0 && <>
                      <Simple table={item.tables[0]}/>
                    </>}
                    <Typography
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                    <br /> 
                    {item.tweetInjects && item.tweetInjects.map((inject, index) => (
                      <><FakeTweet config={inject} /> <br /></>
                    ))}
                     {item.fbInjects && item.fbInjects.map((inject, index) => (
                      <><Media post={inject}/> </>
                    ))}

                    
                    {item.maps && item.maps.length > 0 && <>
                      <br /><br />
                      <SimpleMap
                        height={'480px'}
                        map={item.maps[0]}
                      />
                    </>}
                    {item.followupQuestions && item.followupQuestions.length > 0 && <>
                      <br/><b>Related Questions</b><br/>
                      <List
                        sx={{
                          width: '100%',
                          listStyleType: 'disc',
                          padding: 2
                        }}
                      >
                        {item.followupQuestions.map((prompt, index) => (
                          <ListItem alignItems={'flex-start'} sx={{ display: 'list-item' }}>
                            <Typography
                              component={Link}
                              style={{ cursor: 'pointer' }}
                              color={'text.primary'}
                              onClick={() => {
                                getAnswerWithPrompt(prompt)
                              }}
                            >
                              {prompt}
                            </Typography>
                          </ListItem>))}
                      </List>
                      </>}
                  </>
                )}

                {item.type === messageType.answer && <Box 
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end"
                  }}>
                    <IconButton 
                      sx={{
                        border: "solid 1px"
                      }} 
                      onClick={downloadClick(index)}
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Box>}

                </Box>
                {/* {(onTypingComplete || index < messages.length - 1) && (item.confidence || item.cot) && item.type === messageType.answer &&
                <Box
                  width={'20%'}
                  marginLeft={2}
                >  
                  <Box 
                    border={1} 
                    borderRadius={3}
                    padding={2}
                    borderColor={'text.secondary'}
                  >
                    <Box width={'100%'} display={'flex'} justifyContent="center">
                      <Typography>Confidence Signals</Typography>
                    </Box>
                    <br />
                  
                    <Typography color={'text.secondary'} marginBottom={2}>Confidence Score</Typography>
                    <Typography>{item.confidence}</Typography>
                    <br />
                    <Typography color={'text.secondary'} marginBottom={2}>Reasoning Process</Typography>
                    <Typography variant='body2' dangerouslySetInnerHTML={{ __html: item.cot }} />
                  </Box>
                </Box>
                } */}
                </Box>
              ))}
          </Box>
        </Box>

        <Stack
          width="100%"
          minHeight="73px"
          alignItems="left"
          justifyContent="center"
          borderTop="1px solid #2c2c2c"
          bgcolor="#000"
          zIndex={3}
          style={{ position: 'fixed', borderRadius: 15, }}
          marginBottom={{ xs:15, md: 5 }}          
        >
          <Box
            width="100%"
            maxWidth="md"
            display={'flex'}
            alignItems={'center'}
          >
            <FormControl fullWidth variant="outlined">
              <Box marginLeft={2} sx={{ flexDirection: 'space-between',  display: 'flex'}}>
                {team != 'doc' && <Select
                  disableUnderline={true}
                  variant="standard"
                  disabled={onRequest}
                  value={productType}
                  label="Product Type"
                  onChange={(e) => {
                    // reconsider this
                    if (e.target.value != productType) {
                      setSession([])
                      setSessionId(crypto.randomUUID())
                    }

                    if (e.target.value === 'ama') {
                      setTeam('ama')
                    } else if (e.target.value === 'tweet' ||  e.target.value === 'fb') {
                      setTeam('inject')
                    } else if (e.target.value === 'ascope' || e.target.value === 'tem' || e.target.value === 'tdt' || e.target.value === 'hvt' ) {
                      setTeam('ipb')
                    }

                    setProductType(e.target.value)
                  }}
                >
                  <MenuItem value={'ama'}>{productTypes['ama']}</MenuItem>
                  <MenuItem value={'tweet'}>{productTypes['tweet']}</MenuItem>
                  <MenuItem value={'fb'}>{productTypes['fb']}</MenuItem>
                  <MenuItem value={'ascope'}>{productTypes['ascope']}</MenuItem>
                  <MenuItem value={'tem'}>{productTypes['tem']}</MenuItem>
                  <MenuItem value={'tdt'}>{productTypes['tdt']}</MenuItem>
                  <MenuItem value={'hvt'}>{productTypes['hvt']}</MenuItem>
                  {/* <MenuItem value={'threat_cap'}>{productTypes['threat_cap']}</MenuItem>
                  <MenuItem value={'threat_coa'}>{productTypes['threat_coa']}</MenuItem>
                  <MenuItem value={'sitemp'}>{productTypes['sitemp']}</MenuItem> */}
                </Select>}

                <Box width="100%">
                  <OutlinedInput
                    inputRef={inputRef}
                    fullWidth
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                      }
                    }}
                    endAdornment={
                      (onRequest || isUploading) ? (
                        <CircularProgress size="1.5rem" />
                      ) : (
                        <Box sx={{
                          display: 'flex'
                          }}
                        >
                          <IconButton
                            component="label"
                            disabled={isUploading}            
                          >
                            <UploadFileIcon/>
                            <input
                              type="file"
                              hidden
                              accept=".pdf"
                              multiple
                              onChange={handleFileChange}
                            />
                          </IconButton>
                          <IconButton 
                            disabled={question.trim().length < 3} 
                            onClick={getAnswer}
                          >
                            <SendOutlinedIcon />
                          </IconButton>
                        </Box>
                      )
                    }
                    autoFocus
                    disabled={onRequest}
                    multiline={true}
                    onKeyUp={onEnterPress}
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    placeholder="Ask something..."
                  />
                </Box>

                {/* {team === 'ama' && <Box 
                  display={'flex'}
                  alignItems={'center'} 
                  marginRight={2}
                >
                  <Checkbox 
                    checked={drawMap}
                    onClick={() => setDrawMap(!drawMap) }/>
                  <Typography color={'text.secondary'}>
                    Map
                  </Typography>
                </Box>} */}
                 
              </Box>
            </FormControl>
          </Box>
        </Stack>

      </Stack>
      <Dialog
        open={openPop}
        onClose={handlePopClose}
      >
        <Stack spacing={1} maxWidth={560}>
          <Typography
            padding={2}
            color={'text.secondary'}
          >
            Thank you for using Pytho! Please submit any feedback, feature requests, bugs, etc through this form and we will follow up.
          </Typography>
          <Box
            padding={2}
            width="100%"
            maxWidth="md"
            display={'flex'}
            alignItems={'center'}
          >
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                rows={5}
                endAdornment={
                  <IconButton disabled={feedback.trim().length < 3} onClick={submitFeedback}>
                    <SendOutlinedIcon />
                  </IconButton>
                }
                autoFocus
                multiline={true}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="What would you like to see?"
              />
            </FormControl>
          </Box>
        </Stack>
      </Dialog>
    </Box>
      {/* {team === 'ipb' && <Box
       sx={{ 
        minHeight: 180, 
        flexGrow: 1, 
        maxWidth: 300,
        marginTop: 2,
        position: 'fixed',
        marginLeft: 140,
      }}>
        <TreeView
          width="100%"
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <TreeItem nodeId="1" label="Documents">
            {files.map((f, index) => (
            <TreeItem nodeId={f.name} label={f.name} onClick={() => window.open(f.url, "_blank")} />
            ))}
          </TreeItem>
        </TreeView>
      </Box>} */}
    </Box>
  );
}