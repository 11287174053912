import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Simple = ({table}) => {
  const [open, setOpen] = React.useState(false);
  const [cellText, setCellText] = React.useState(false);
  const [cellTitle, setCellTitle] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  return (
    <>
      <Typography 
        marginBottom={2} 
        textAlign={'center'}
        variant='h5'
      >
        {table.title}
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {table.columns.map((column) => (
                <TableCell>
                  <Typography>{column}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>  
           {table.rows.map((row) => (
            <TableRow
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {row.name && <TableCell 
                component="th" 
                scope="row"  
                sx={{ position: 'sticky', backgroundColor: '#131313', }}
              >
                <Typography>{row.name}</Typography>
              </TableCell>}
              {row.cells.map((cell) => (
                <TableCell
                  sx={{
                    cursor: 'pointer',
                  }}  
                  onClick={() => {
                    if (cell.text.length > 20) {
                      setCellText(cell.text)
                      setCellTitle(cell.title)
                      setOpen(true)
                    }
                  }
                }>
                  <Typography dangerouslySetInnerHTML={{ __html: (cell.text.length > (table.columns.length > 2 ? 20 : 130) ? cell.text.substring(0, (table.columns.length > 2 ? 20 : 130)) + ' ...' : cell.text) }} />
                </TableCell>
              ))}
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {cellTitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography dangerouslySetInnerHTML={{ __html: cellText }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    <br />  
    </>
  );
};

export default Simple;
