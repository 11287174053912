import { React } from 'react';
import { useAppContext } from 'lib/contextLib'; 

import Box from '@mui/material/Box';
import Chat from './Chat';
import { Typography, Link } from '@mui/material';
import { useEffect  } from "react";


export default function ChatHome() {
  const { setOpenSidebar, navState, user, setNavState } = useAppContext();

  
  return (
    <>
    {navState === 'chat' ? 
      <Chat /> :
     
      <Box marginLeft={25} marginTop={12} >
        <Typography 
          sx={{
            flexGrow: 1,
            }}
          variant="h5"
        >
          Wargame Operations
        </Typography>
        <Typography  
          sx={{
            flexGrow: 1,
            marginTop: 3,
          }}
        >
          Welcome {user.firstName}! Today is {new Date().toLocaleDateString('en-US', { 
            weekday: 'long',
            year: 'numeric', 
            month: 'long', 
            day: 'numeric' 
        })}. Here is a summary of the <Link target="_blank" href='https://www.defensenews.com/ebb/'>Early Bird Digest.</Link>

        </Typography>
        {user.summary ? 
          <Typography dangerouslySetInnerHTML={{ __html: user.summary.ebb }} /> :
          <Typography>
            <ul>
              <li>France is developing a wingman drone for its Rafale fighter jet to enhance combat capabilities.</li>
              <li>The Army is using AI to help FEMA during Hurricane Helene relief by mapping essential data in real time.</li>
              <li>Israel's defense minister postponed his Washington visit amid talks of responding to Iran's recent missile attack.</li>
              <li>U.S. military support is enabling Israel to shift power dynamics in the Middle East.</li>
              <li>Finnish startups are booming due to NATO expansion.</li>
            </ul>
          </Typography>}
        <Box
          sx={{
            backgroundColor: '#F2F6D8',
            borderRadius: '10px',
            height: 50,
            width: 150,
            display: 'flex',
            alignItems: 'center',
            marginTop: 6,
            cursor: 'pointer',
          }}  
          onClick={ () => setNavState('chat') }
        > 
          <Typography 
            sx={{
              color: 'black',
              paddingLeft: 2,
            }}
          >
            Launch Pytho
          </Typography>
        </Box>
      </Box>
        
    }
    </>
  );
}
